import React, { useCallback, useState, useMemo } from 'react';
import styled from 'styled-components';
import { getAutoCompleteResults } from '../BackendAPI';
import { Loader } from './primitives';

const Form = styled.form`
  .form-control {
    cursor: pointer;
  }

  position: relative;

  .autocomplete-dropdown-container {
    position: absolute;
    width: 100%;
    z-index: 1000;
    overflow-y: scroll;
    max-height: 250px;
    border-left: 1px solid #d1d0d1;
    border-right: 1px solid #d1d0d1;
    border-bottom: 1px solid #d1d0d1;
    border-radius: 0 0 5px 5px;
    display: block;
    top: calc(100% - 2px);
    background: white;
    padding: 0;

    &.is-mobile {
      width: 100%;
    }

    li {
      cursor: pointer;
      display: inline-block;
      padding: 10px;
      position: relative;
      z-index: 1000;
      width: 100%;
      background: white;
      border-bottom: 1px solid #f2f2f2;

      &:hover {
        background: #f2f2f2;
      }

      p {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        padding: 0;
        height: 36px;
        font-size: 10px;
        line-height: 1.2;
        color: #898989;
      }

      &.loader {
        display: flex;
        justify-content: center;
      }
    }
  }
`;
export default function SearchBox() {
  const [suggestions, setSuggestions] = useState([]);
  const [isActive, setActive] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState<null | number>(null);
  const [loading, setLoading] = useState(false);

  const handleInput = useCallback(async (e) => {
    const search = e.target.value;

    if (search.length > 2) {
      setLoading(true);
      try {
        const results = await getAutoCompleteResults(search);
        if (results?.length > 0) {
          setSuggestions(
            results.map((a: { identifier: any; short_description: string }) => ({
              ...a.identifier,
              description: a.short_description,
            })),
          );
        } else {
          setSuggestions([]);
          setHighlightedIndex(null);
        }
      } catch (err) {
        setSuggestions([]);
        setHighlightedIndex(null);
      } finally {
        setLoading(false);
      }
    } else {
      setSuggestions([]);
      setHighlightedIndex(null);
    }
  }, []);

  // const handleKeyDown = useCallback(
  //   (e) => {
  //     console.log(e.keyCode);
  //     if (e.keyCode === 13) {
  //       // TODO: fix this. handleSelect would not trigger, so I had to duplicate the logic inline
  //       // handleSelect(filteredSuggestions[highlightedIndex]);
  //       // keydown
  //     } else if (e.keyCode === 40) {
  //       if (highlightedIndex < suggestions.length - 1) {
  //         setHighlightedIndex(highlightedIndex + 1);
  //       }
  //       // keyup
  //     } else if (e.keyCode === 38) {
  //       if (highlightedIndex !== 0) {
  //         setHighlightedIndex(highlightedIndex - 1);
  //       }
  //     }
  //   },
  //   [highlightedIndex, suggestions.length],
  // );

  const renderedSuggestions = useMemo(() => {
    return suggestions.map(({ value, permalink, description }, index) => (
      <a
        key={permalink}
        onClick={() => setActive(false)}
        href={`/company/${permalink}`}
        target="_blank"
        rel="noreferrer"
      >
        <li onMouseEnter={() => setHighlightedIndex(index)}>
          <strong>{value}</strong>
          <p className="description">{description}</p>
        </li>
      </a>
    ));
  }, [suggestions]);

  const renderedLoader = useMemo(() => {
    return (
      <li className="loader">
        <Loader />
      </li>
    );
  }, []);

  const handleFocus = useCallback(() => setActive(true), []);

  const handleBlur = useCallback(() => {
    if (highlightedIndex === null) setActive(false);
  }, [highlightedIndex]);

  return (
    <Form method="get" action="/search">
      <input
        name="q"
        type="text"
        className="form-control"
        placeholder="Search"
        onChange={handleInput}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {(suggestions?.length > 0 || loading) && isActive && (
        <ul
          className="autocomplete-dropdown-container"
          onMouseLeave={() => setHighlightedIndex(null)}
        >
          {loading ? renderedLoader : renderedSuggestions}
        </ul>
      )}
    </Form>
  );
}

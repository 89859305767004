import React, { useContext, useCallback, useMemo } from 'react';
import { Modal, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { ProspectContext } from '../../contexts/ProspectContext';
import { ProspectScoreV2Context } from '../../contexts/ProspectScoreV2Context';
import { CompanyVerticalContext } from '../../contexts/CompanyVerticalContext';
import { STAGE_TO_TYPE_MAP } from '../../constants';
import {
  createNewProspect,
  createNewProspectScores,
  createNewProspectScoresV2,
  patchProspectScoreV2,
  patchCompany,
  createNewFeedItem,
} from '../../BackendAPI';
import { getUserFirstName } from '../../utils/Auth';
import { Flex } from '../primitives';
import { prospectScoresV2 } from './prospectScoresV2';

const StyledForm = styled.form`
  li.form-checkbox-row {
    border-bottom: none;
    padding: 0;
  }
  .form-checkbox-row {
    &:last-child {
      margin-bottom: 0;
    }
    .form-check {
      margin: 0;
      .form-check-label i {
        top: 4px;
        p {
          margin-bottom: 0;
        }
      }
      .form-check-label > i.checked {
        display: block;
      }
    }
  }
  .category-header {
    h5 {
      font-weight: bold;
    }
    p {
      color: #898989;
      margin-bottom: 10px;
      font-size: 12px;
    }
  }
`;

const getActiveValue = (list) => {
  return list.find((l) => l.active)?.value;
};

const getLabel = (rating) => {
  switch (rating) {
    case 1:
      return 'Bottom 25%';
    case 2:
      return 'Middle 50%';
    case 3:
      return 'Top 25%';
    default:
      return '-';
  }
};

export default function ProspectScoresV2Modal({ refreshFeed, withVerticals }) {
  const [state, dispatch] = useContext(ProspectContext);
  const [scoresV2State, scoresV2Dispatch] = useContext(ProspectScoreV2Context);
  const [, verticalDispatch] = useContext(CompanyVerticalContext);
  const stageValue = getActiveValue(state.stage.options);
  const stageType = STAGE_TO_TYPE_MAP[stageValue];
  const prospectScoresV1 = state.prospectScores.filter((s) => s.stage_type_id === stageType);
  const active = !!state.prospectId;

  const prospectScoresV2State =
    stageValue === 'Seed'
      ? scoresV2State.filter((a) => a.category !== 'Financial Metrics')
      : scoresV2State;

  const createProspect = useCallback(async () => {
    const { permalink, owner, stage, source, sourceType, awareTv, initialContactDate } = state;

    const newProspect = await createNewProspect({
      permalink,
      owner: getActiveValue(owner.options),
      initial_contact_date: initialContactDate.value,
      stage: getActiveValue(stage.options),
      stage_type_id: stageType,
      source: getActiveValue(source.options),
      source_type: getActiveValue(sourceType.options),
      aware_tv: getActiveValue(awareTv.options),
    });

    const prospectId = newProspect.id;
    dispatch({ type: 'ADD_PROSPECT_ID', payload: { prospectId } });

    // PROSPECT SCORE v1
    await createNewProspectScores({ prospect_id: prospectId, prospect_scores: prospectScoresV1 });

    const totalScoreV1 = prospectScoresV1.map((s) => s.value).reduce((a, b) => a + b, 0);
    await patchCompany(permalink, { tv_company_score: totalScoreV1 });

    // PROSPECT SCORE v2
    await createNewProspectScoresV2({
      prospect_id: prospectId,
      prospect_scores: scoresV2State,
    });
    const totalScoreV2 = scoresV2State.map((s) => s.score).reduce((a, b) => a + b, 0);
    await patchCompany(state?.permalink, { prospect_score_v2: totalScoreV2 });

    // Other
    await patchCompany(permalink, { major_status: 'Active' });

    const firstName = getUserFirstName();
    const header = `updated to Prospect by ${firstName}`;
    await createNewFeedItem({
      permalink,
      type: 'status',
      header,
      custom: `{"status": "Prospect", "user": "${firstName}"}`,
    });

    if (refreshFeed) {
      await refreshFeed();
    }
  }, [dispatch, prospectScoresV1, stageType, state, refreshFeed, scoresV2State]);

  const openVerticalModal = useCallback(async () => {
    if (active) {
      const totalScoreV1 = prospectScoresV1.map((s) => s.value).reduce((a, b) => a + b, 0);
      await patchCompany(state.permalink, { tv_company_score: totalScoreV1 });

      const totalScoreV2 = prospectScoresV2State.map((s) => s.score).reduce((a, b) => a + b, 0);
      await patchCompany(state.permalink, { prospect_score_v2: totalScoreV2 });

      if (prospectScoresV2State.some((a) => !a.id)) {
        await createNewProspectScoresV2({
          prospect_id: state.prospectId,
          prospect_scores: scoresV2State,
        });
      }
    } else {
      await createProspect();
    }

    dispatch({ type: 'SET_MODAL', payload: { modal: null, isEditing: false } });
    if (withVerticals) {
      verticalDispatch({ type: 'TOGGLE_VERTICAL_MODAL', value: true });
    }
  }, [
    active,
    dispatch,
    withVerticals,
    prospectScoresV1,
    state.permalink,
    state.prospectId,
    prospectScoresV2State,
    scoresV2State,
    createProspect,
    verticalDispatch,
  ]);

  const verticalButtonCopy = useMemo(() => {
    if (!withVerticals) {
      return 'Submit';
    }
    if (active) {
      return state.isEditing ? 'Edit Verticals' : 'Update Verticals';
    }
    return 'Create Prospect';
  }, [state.isEditing, active, withVerticals]);

  const getRadioClassnames = useCallback(
    (category, rating) => {
      const prospectScore = scoresV2State.find((a) => a.category === category);
      return prospectScore.rating === rating
        ? 'fa fa-check-circle checked'
        : 'fa fa-circle-thin unchecked';
    },
    [scoresV2State],
  );

  const getScore = useCallback(
    (category, rating) => {
      const prospectScore = prospectScoresV2.find((a) => a.category === category);
      const scores = prospectScore.scores.find((a) => a.rating === rating);
      if (stageValue === 'Seed') {
        return scores.seedScore;
      }
      return scores.lateScore;
    },
    [stageValue],
  );

  const toggleChecked = useCallback(
    async (category, rating) => {
      const score = getScore(category, rating);
      scoresV2Dispatch({ type: 'UPDATE_SCORE', payload: { category, rating, score } });

      const prospectScore = prospectScoresV2State.find((a) => a.category === category);
      if (prospectScore.id) {
        await patchProspectScoreV2(prospectScore.id, { score });
        await patchProspectScoreV2(prospectScore.id, { rating });
      }
    },
    [scoresV2Dispatch, getScore, prospectScoresV2State],
  );

  const handleClose = useCallback(async () => {
    const totalScoreV1 = prospectScoresV1.map((s) => s.value).reduce((a, b) => a + b, 0);
    await patchCompany(state.permalink, { tv_company_score: totalScoreV1 });

    const totalScoreV2 = prospectScoresV2State.map((s) => s.score).reduce((a, b) => a + b, 0);
    await patchCompany(state.permalink, { prospect_score_v2: totalScoreV2 });

    if (prospectScoresV2State.some((a) => !a.id) && state.prospectId) {
      await createNewProspectScoresV2({
        prospect_id: state.prospectId,
        prospect_scores: scoresV2State,
      });
    }

    if (refreshFeed) {
      await refreshFeed();
    }
    dispatch({ type: 'SET_MODAL', payload: { modal: null, isEditing: false } });
  }, [
    prospectScoresV1,
    state.permalink,
    state.prospectId,
    prospectScoresV2State,
    refreshFeed,
    dispatch,
    scoresV2State,
  ]);

  const isReady = useMemo(() => {
    return !prospectScoresV2State.every((a) => a.rating > 0);
  }, [prospectScoresV2State]);

  return (
    <div>
      <Modal
        backdrop="static"
        show={state.modal === 4}
        onHide={() => dispatch({ type: 'SET_MODAL', payload: { modal: null, isEditing: false } })}
        className="tv-modal tv-company-modal"
      >
        <Modal.Header>
          <Modal.Title>Scoring (New) {stageValue === 'Seed' ? 'Early' : 'Late'} Stage</Modal.Title>
          <Button
            className="modal-close"
            onClick={() =>
              dispatch({ type: 'SET_MODAL', payload: { modal: null, isEditing: false } })
            }
          >
            <i className="fa fa-times" />
          </Button>
        </Modal.Header>

        <Modal.Body>
          <StyledForm>
            {prospectScoresV2.map((prospectScore) => {
              if (stageValue === 'Seed' && prospectScore.category === 'Financial Metrics') {
                return null;
              }

              return (
                <Flex direction="column" key={prospectScore.category}>
                  <Flex direction="column" className="category-header">
                    <h5>{prospectScore.category}</h5>
                    <p>{prospectScore.description}</p>
                  </Flex>
                  <ul className="list-unstyled list-spaced">
                    {prospectScore.scores.map((score) => (
                      <li
                        className="form-checkbox-row"
                        key={`${prospectScore.category}-${score.key}`}
                      >
                        <div className="form-check form-check-inline">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="radio"
                              value={score.rating}
                              onChange={() => toggleChecked(prospectScore.category, score.rating)}
                            />
                            <p>{getLabel(score.rating)}</p>
                            <i
                              className={getRadioClassnames(prospectScore.category, score.rating)}
                              aria-hidden="true"
                            />
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </Flex>
              );
            })}
          </StyledForm>
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={() =>
              dispatch({
                type: 'SET_MODAL',
                payload: { modal: 3, isEditing: state.isEditing },
              })
            }
          >
            Go Back
          </Button>

          {state?.isEditing ? (
            <Button onClick={handleClose} disabled={isReady}>
              Submit
            </Button>
          ) : (
            <Button onClick={openVerticalModal} disabled={isReady}>
              {verticalButtonCopy}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import styled from 'styled-components';
import debounce from 'lodash.debounce';
import captureError from '../../utils/sentry';
import { Loader, Flex } from '../primitives';
import ProspectDetails from './ProspectDetails';
import RichTextEditor from '../TextEditor';
import { patchCompany } from '../../BackendAPI';

const ModalContainer = styled(Modal)`
  .modal-dialog {
    max-width: 96%;
    min-height: 91vh;
  }
  .modal-content {
    height: 91vh;
  }
  .modal-header {
    padding: 6px 20px 18px 0;
    margin-bottom: 6px;
  }
  .modal-close {
    position: absolute;
    z-index: 100;
  }
  .outer-grid {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 20px;
    height: 91%;
  }

  .top-left {
    background-color: #fff;
    height: 77vh;
  }

  .top-right {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0;
  }

  .right-col-1,
  .right-col-2 {
    background-color: #fff;

    .column-content {
      border-left: 1px solid #dcdcdc33;
      padding: 20px;
      height: 100%;
      display: block;
      height: 77vh;
      overflow-y: scroll;
      position: relative;
    }
  }

  .right-col-1 {
    .column-content {
      padding: 0;

      ul {
        padding-top: 0;
        margin: 0;
      }

      .RichEditor-root {
        border: none;
        padding: 15px;
        height: 100%;
        padding-bottom: 80px;

        .DraftEditor-editorContainer {
          height: auto;
        }

        .RichEditor-controlsContainer {
          position: sticky;
          top: 0;
          background: white;
          width: 100%;
          z-index: 100;
          padding-bottom: 10px;
        }

        .RichEditor-editor {
          margin-top: 0;

          .public-DraftEditor-content {
            padding: 0;
            padding-top: 15px;
            padding-bottom: 60px;
            margin: 0;

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              margin-top: 1rem;
              &:first-of-type {
                margin-top: 0;
              }
            }
          }
        }

        &.read-only {
          padding-bottom: 60px;

          .RichEditor-editor {
            .public-DraftEditor-content {
              padding-top: 0;
            }
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .outer-grid {
      grid-template-columns: 1fr;
    }
    .top-left {
      display: none;
    }
  }

  .summary-container {
    overflow-y: scroll;
  }

  .content-disclaimer {
    border-top: 1px solid #dcdcdc33;
    border-bottom: 1px solid #dcdcdc33;
    position: absolute;
    width: 100%;
    height: 50px;
    bottom: 0;
    z-index: 100;
    background: white;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      margin: 0;
      color: rgb(154, 154, 154);
    }

    .tv-button {
      margin: 0 !important;
    }
  }
`;

const ProspectSummary = ({ prospectSummary, permalink }) => {
  const [editing, setEditing] = useState(false);

  const debouncedSaveRichText = debounce(async (stringifiedRichText) => {
    const prospect_summary = {
      ...prospectSummary,
      summary: stringifiedRichText,
    };

    try {
      await patchCompany(permalink, { prospect_summary });
    } catch (err) {
      captureError(`Error saving prospect summary update: ${err}`);
    }
  }, 500);

  return (
    <>
      <Flex width="100%" height="100%" direction="column" className="summary-container">
        <RichTextEditor
          readOnly={!editing}
          externalOnChange={debouncedSaveRichText}
          richText={prospectSummary.summary}
        />
      </Flex>
      <div className="content-disclaimer">
        <p>This content is generated by AI</p>
        <button type="button" className="tv-button" onClick={() => setEditing(!editing)}>
          {editing ? 'Done' : 'Edit'}
        </button>
      </div>
    </>
  );
};

export default function ProspectSummaryModal({
  close,
  permalink,
  prospectScoreV1,
  prospectScoreV2,
  onWatchlist,
  isVerticalPriority,
  prospectSummary,
}) {
  return (
    <ModalContainer
      backdrop="static"
      show
      onHide={close}
      className="tv-modal tv-prospect-summary-modal"
    >
      <Button className="modal-close" onClick={close}>
        <i className="fa fa-times" />
      </Button>

      <Modal.Body>
        <div className="outer-grid">
          <div className="top-left">
            <Modal.Header>
              <Modal.Title>View Document</Modal.Title>
            </Modal.Header>

            {prospectSummary.url ? (
              <iframe
                src={`https://api.thomvest.com/${prospectSummary.url}`}
                width="100%"
                height="100%"
              />
            ) : (
              <Flex width="100%" height="77vh" justify="center" align="center">
                {prospectSummary.url === null ? (
                  <Loader />
                ) : (
                  <p>There was an issue loading this document.</p>
                )}
              </Flex>
            )}
          </div>

          <div className="top-right">
            <div className="right-col-1">
              <Modal.Header>
                <Modal.Title>Company Summary</Modal.Title>
              </Modal.Header>

              <div className="column-content">
                {prospectSummary?.summary ? (
                  <ProspectSummary prospectSummary={prospectSummary} permalink={permalink} />
                ) : (
                  <Flex
                    width="100%"
                    height="100%"
                    justify="center"
                    align="center"
                    direction="column"
                  >
                    <h6>There was an issue generating the summary</h6>
                    <p>Please try reuploading the pitch deck in the prospect modal.</p>
                  </Flex>
                )}
              </div>
            </div>

            <div className="right-col-2">
              <Modal.Header>
                <Modal.Title>Prospect Details</Modal.Title>
              </Modal.Header>
              <div className="column-content">
                <ProspectDetails
                  prospectScoreV1={prospectScoreV1}
                  prospectScoreV2={prospectScoreV2}
                  onWatchlist={onWatchlist}
                  isVerticalPriority={isVerticalPriority}
                  direction="column"
                  isModal
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </ModalContainer>
  );
}

import React, { useContext } from 'react';

import { ProspectContext } from '../../contexts/ProspectContext';
import ProspectModal from './ProspectModal';
import ProspectScoresModal from './ProspectScoresModal';
import ProspectScoresV2Modal from './ProspectScoresV2Modal';
import ProspectPDFUploadModal from './ProspectPDFUploadModal';

const ProspectModals = ({ companySummary, refreshFeed, withVerticals = true, fetchPdfSummary }) => {
  const [state] = useContext(ProspectContext);

  return (
    <>
      {state.modal === 1 && <ProspectModal refreshFeed={refreshFeed} />}
      {state.modal === 2 && (
        <ProspectPDFUploadModal
          companySummary={companySummary}
          refreshFeed={refreshFeed}
          fetchPdfSummary={fetchPdfSummary}
        />
      )}
      {state.modal === 3 && (
        <ProspectScoresModal refreshFeed={refreshFeed} withVerticals={withVerticals} />
      )}
      {state.modal === 4 && (
        <ProspectScoresV2Modal refreshFeed={refreshFeed} withVerticals={withVerticals} />
      )}
    </>
  );
};

export default ProspectModals;

import React, { useContext, useState, useCallback, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ProspectContext } from '../../contexts/ProspectContext';
import { Flex, Loader } from '../primitives';
import { uploadFile, getDocuments, patchCompany } from '../../BackendAPI';
import captureError from '../../utils/sentry';
import { getUserEmail } from '../../utils/Auth';
import CompanyDocuments from '../company/CompanyDocuments';

export default function ProspectPDFUploadModal({ refreshFeed, fetchPdfSummary, companySummary }) {
  const [state, dispatch] = useContext(ProspectContext);
  const [loading, setLoading] = useState('...loading');
  const [error, setError] = useState(false);
  const userEmail = getUserEmail();
  const [deckFile, setDeckFile] = useState(null);
  const { permalink, prospectId } = state;
  const active = prospectId !== null;

  const fetchAndSetSummaryFile = useCallback(async () => {
    try {
      const prospectSummary = companySummary.prospect_summary;
      const documents = await getDocuments(permalink);
      const document = documents.find((doc) => doc.uuid === prospectSummary.document_uuid);
      if (document) {
        setDeckFile(document);
      }
    } catch (err) {
      captureError(`Failed to fetch and set summary gile ${permalink}:${err}`);
    } finally {
      setLoading(false);
    }
  }, [permalink, companySummary.prospect_summary]);

  useEffect(() => {
    if (companySummary.prospect_summary) {
      fetchAndSetSummaryFile();
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFileUpload = useCallback(
    async (event) => {
      setLoading('...uploading document');
      const file = event.target.files[0];
      const data = new FormData();
      data.append('file', file);

      try {
        await uploadFile(data, permalink, userEmail);
        const documents = await getDocuments(permalink);
        const document = documents.find((doc) => doc.document_name === file.name);
        setDeckFile(document);
        fetchPdfSummary(document);
      } catch (err) {
        setError(true);
        captureError(`Failed to upload ${permalink} documents :${err}`);
      } finally {
        setLoading(false);
      }
    },
    [fetchPdfSummary, permalink, userEmail],
  );

  const reset = useCallback(() => {
    setError(false);
    setLoading(false);
    setDeckFile(null);
  }, []);

  const handleDeleteDocument = useCallback(async () => {
    try {
      await patchCompany(permalink, { prospect_summary: null });
      await refreshFeed();
      reset();
    } catch (err) {
      captureError(`Failed to handleDeleteDocument: ${permalink} :${err}`);
    }
  }, [permalink, refreshFeed, reset]);

  return (
    <Modal
      backdrop="static"
      show={state.modal === 2}
      onHide={() => dispatch({ type: 'SET_MODAL', payload: { modal: null, isEditing: false } })}
      className="tv-modal tv-company-modal"
    >
      <Modal.Header>
        <Modal.Title>Upload Pitch Deck</Modal.Title>
        <Button
          className="modal-close"
          onClick={() =>
            dispatch({ type: 'SET_MODAL', payload: { modal: null, isEditing: false } })
          }
        >
          <i className="fa fa-times" />
        </Button>
      </Modal.Header>

      <Modal.Body>
        <Flex width="100%" height="400px" direction="column">
          <p>
            Please upload an investor presentation for the current round. If you have a Docsend
            link, please use a service like Docsend2PDF.com to convert to PDF format.
          </p>
          {!loading && !deckFile && !error && (
            <Flex width="100%" height="100%" justify="center" align="center" direction="column">
              <Flex marginTop="10">
                <label className="tv-button btn-file">
                  + Add File
                  <input type="file" style={{ display: 'none' }} onChange={handleFileUpload} />
                </label>
              </Flex>
            </Flex>
          )}
          {loading && (
            <Flex width="100%" height="100%" justify="center" align="center" direction="column">
              <h6>{loading ?? '...uploading document'}</h6>
              <Flex marginTop="10">
                <Loader />
              </Flex>
            </Flex>
          )}
          {error && (
            <Flex width="100%" height="100%" justify="center" align="center" direction="column">
              <h6>There was an issue uploading the document</h6>
              <Flex marginTop="10">
                <Button onClick={reset}>Try again</Button>
              </Flex>
            </Flex>
          )}
          {deckFile && (
            <Flex width="100%" height="100%" justify="center" align="center" direction="column">
              <CompanyDocuments
                company={permalink}
                documentsFromProps={[deckFile]}
                handleDeleteDocument={handleDeleteDocument}
                isSingleDocument
              />
            </Flex>
          )}
        </Flex>
      </Modal.Body>

      <Modal.Footer>
        <Flex justify="space-between" fill align="center">
          <Button
            onClick={() => {
              dispatch({ type: 'SET_MODAL', payload: { modal: 1, isEditing: false } });
            }}
          >
            Back
          </Button>
          <Button
            onClick={() =>
              dispatch({ type: 'SET_MODAL', payload: { modal: 3, isEditing: state.isEditing } })
            }
          >
            Next: {active ? 'Update' : 'Add'} Scores
          </Button>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}

import React, { useCallback, useContext, useMemo, useState } from 'react';
import '../../styles/react-datetime.css';
import styled from 'styled-components';
import { ProspectContext } from '../../contexts/ProspectContext';
import { Card, Flex } from '../primitives';
import ProspectSummaryModal from '../prospect/ProspectSummaryModal';
import ProspectDetails from '../prospect/ProspectDetails';

const SummaryLink = styled.a`
  margin-left: 20px;
  color: #2579a9;
  margin-bottom: 15px;
  top: 2px;
  position: relative;
`;

const Button = styled.button`
  margin-top: 0;
`;

export default function CompanyProspect({
  companySummary,
  prospectScoreV1,
  prospectScoreV2,
  onWatchlist,
  isVerticalPriority,
  refreshCompanySummary,
}) {
  const [prospectContextState, dispatch] = useContext(ProspectContext);
  const { permalink, prospectId } = prospectContextState;
  const isActive = useMemo(() => !!prospectId, [prospectId]);
  const [modalActive, setModalActive] = useState(false);

  const openProspectSummary = useCallback(() => setModalActive(true), []);

  const handleCloseModal = useCallback(() => {
    refreshCompanySummary();
    setModalActive(false);
  }, [refreshCompanySummary]);

  if (isActive) {
    return (
      <>
        <Card className="card prospects-block" marginBottom="20" fill>
          <div className="card-block">
            <Flex direction="row" align="flex-end">
              <h3>Prospect Details</h3>
              {companySummary?.prospect_summary && (
                <SummaryLink href="#" onClick={openProspectSummary}>
                  View summary
                </SummaryLink>
              )}
            </Flex>

            <ProspectDetails
              prospectScoreV1={prospectScoreV1}
              prospectScoreV2={prospectScoreV2}
              onWatchlist={onWatchlist}
              isVerticalPriority={isVerticalPriority}
            />

            <Button
              type="button"
              className="tv-button"
              onClick={() => {
                dispatch({ type: 'SET_MODAL', payload: { modal: 1, isEditing: true } });
              }}
            >
              Edit
            </Button>
          </div>
        </Card>

        {modalActive && companySummary?.prospect_summary && (
          <ProspectSummaryModal
            close={handleCloseModal}
            prospectSummary={companySummary.prospect_summary}
            permalink={permalink}
            prospectScoreV1={prospectScoreV1}
            prospectScoreV2={prospectScoreV2}
            onWatchlist={onWatchlist}
            isVerticalPriority={isVerticalPriority}
          />
        )}
      </>
    );
  }

  return null;
}
